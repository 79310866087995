import { createTheme } from "@mui/material";

export default createTheme({
	palette: {
		// mode: "dark"
		primary: {
			main: "#00CC00"
		},
		info: {
			main: "#BD782C"
		},
		secondary: {
			main: "#00CC00"
		},
		grey: {
			"500": "#3D3C36"
		}
	}
})
