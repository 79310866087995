import React from 'react';
import { RoundInformation, Tournament } from "../domain";
import { CircularProgress, Table, TableBody, TableHead, TableRow, useTheme } from "@mui/material";
import moment from "moment";
import dateFormats from "../system/dateFormats";
import { BorderedTableCell, FirstTableCell, HeaderTableCell, StyledTableRow } from "../system/styledComponents";

interface Props {
	roundRows: RoundInformation[]
	tournaments: Tournament[]
}

const TournamentOverviewTable = ({ roundRows, tournaments }: Props) => {
	const theme = useTheme();
	
	const saltatioFloor = "2";
	const hsvFloor = "1";
	
	return (
		<>
			{(!roundRows || !tournaments) && (
				<CircularProgress size={48} />
			)}
			{roundRows && tournaments && (
				<Table>
					<colgroup>
						<col width="auto" />
						{tournaments && tournaments.map((t, i) => (
							<col key={i} width="auto" />
						))}
					</colgroup>
					<TableHead>
						<TableRow>
							<HeaderTableCell
								sx={{
									backgroundColor: theme.palette.grey[500]
								}}
							></HeaderTableCell>
							{tournaments && tournaments.map((t, i) => (
								<HeaderTableCell 
									key={i}
									sx={{
										...(t.floor === saltatioFloor && {
											backgroundColor: theme.palette.primary.main
										}),
										...(t.floor === hsvFloor && {
											backgroundColor: theme.palette.secondary.main
										})
									}}
								>{t.name}</HeaderTableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<FirstTableCell>Paare</FirstTableCell>
							{tournaments && tournaments.map((t, i) => (
								<BorderedTableCell key={i}>{t.couples}</BorderedTableCell>
							))}
						</TableRow>
						{roundRows && roundRows.map((drn, i) => (
							<StyledTableRow key={i}>
								<FirstTableCell>{drn.roundName}</FirstTableCell>
								{tournaments && tournaments.map((t, i) => {
									const round = t.rounds.filter(f => f.line === drn.line)[0];
									const startDate = round?.startDate;
									const highlight = !!round?.isRunning && !round?.isFinished;

									return (
										<BorderedTableCell key={i} sx={{
											...(highlight && {
												backgroundColor: theme.palette.info.main,
												color: "white"
											})
										}}>
											{startDate && moment(startDate).format(dateFormats.time)}
										</BorderedTableCell>
									);
								})}
							</StyledTableRow>
						))}
					</TableBody>
				</Table>
			)}
		</>
	);
}

export default TournamentOverviewTable;
