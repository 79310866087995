import React, { useState } from 'react';
import { Box, Typography, useTheme } from "@mui/material";
import TournamentOverviewTable from "./TournamentOverviewTable";
import { RoundInformation, Tournament } from "../domain";
import useAsyncEffect from "../useAsyncEffect";
import axios from "axios";
import TournamentDisplay from "./TournamentDisplay";
import { NotificationBox, TurnedTableCell } from "../system/styledComponents";
import moment from "moment";
import dateFormats from "../system/dateFormats";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { uiConfiguration } from "../system/atoms";

const iconPath = process.env.PUBLIC_URL;

interface RoundPlan {
	tournaments: Tournament[]
	roundInformation: RoundInformation[]
}

const TournamentOverview = () => {
	const theme = useTheme();
	let { id } = useParams();

	const [roundRows, setRoundRows] = useState<RoundInformation[]>([]);
	const [tournaments, setTournaments] = useState<Tournament[]>([]);
	const [lastRefresh, setLastRefresh] = useState<moment.Moment>(moment());
	
	const uiConfig = useRecoilValue(uiConfiguration);
	
	const loadData = async () => {
		const response = await axios.get<RoundPlan>("/tournaments/round-plan", {
			//const response = await axios.get<RoundPlan>("https://localhost:7052/tournaments/round-plan", {
			params: {
				floor: id
			}
		});

		setRoundRows(response.data.roundInformation);
		setTournaments(response.data.tournaments);
		setLastRefresh(moment());
	};

	// initial load
	useAsyncEffect(loadData, []);

	// period load
	useAsyncEffect(async () => {
		const intervalId = setInterval(async () => {
			await loadData();
		}, 1000 * 60);

		return () => clearInterval(intervalId);
	}, []);
	
	const runningTournament = tournaments.filter(t => t.isRunning)[0];

	return (
		<Box pt={2.5} width="100vw" height="100vh" display="flex" flexDirection="column">
			<Box pl={6} pr={6} width="100%" display="flex" justifyContent="space-between">
				<img style={{ maxWidth: "400px" }}
					 src={`${iconPath}${uiConfig?.logo}`} alt="logo" />
				<Box>
					<Box><Typography fontSize="30px">Letzte Aktualisierung:</Typography></Box>
					<Box textAlign="right">{lastRefresh.format(dateFormats.dateTime)}</Box>
				</Box>
			</Box>
			<Box flexGrow={1}></Box>
			<Box flexGrow={1} display="flex">
				<Box
					mx={2}
					display="inline-flex"
					alignItems="center"
				>
					<TurnedTableCell sx={{ color: theme.palette.primary.main, letterSpacing: 6 }}>
						Aktuell
					</TurnedTableCell>
				</Box>
				{tournaments && (
					<>
						<Box ml={5} width="15%" display="inline-block" whiteSpace="nowrap">
							{runningTournament?.name}
							<br />
							<br />
							{runningTournament?.rounds.filter(f => f.isRunning)[0]?.roundName}
						</Box>
						{tournaments.filter(t => t.isRunning && !t.isFinished).slice(0,4).map((t,i) => (
							<TournamentDisplay tournament={t} key={i} />
						))}
					</>
				)}
			</Box>
			<Box display="flex">
				<Box
					mx={2}
					display="inline-flex"
					alignItems="center"
				>
					<TurnedTableCell sx={{ color: theme.palette.primary.main, letterSpacing: 6 }}>
						Tagesablauf
					</TurnedTableCell>
				</Box>
				
				<Box width="100%" mx={2}>
					<TournamentOverviewTable
						roundRows={roundRows}
						tournaments={tournaments}
					/>
				</Box>
			</Box>
			<Box width="100%" flexGrow={0.5} mt={3}>
				<NotificationBox>
					Die angegebenen Zeiten können abhängig vom Ablauf variieren.
					<br />
					Bitte achten Sie auf die Ansagen der Turnierleitung.
					<br />
					Änderungen vorbehalten.
				</NotificationBox>
			</Box>
		</Box>
	);
}

export default TournamentOverview;
