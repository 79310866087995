import React from 'react';
import { Tournament } from "../domain";
import { styled, Table, TableBody, TableRow, useTheme } from "@mui/material";
import dateFormats from "../system/dateFormats";
import moment from "moment";
import { BorderlessTableCell } from "../system/styledComponents";

interface Props {
	tournament: Tournament
}

const MinimalWidthTable = styled(Table)({
	width: "20%",
	display: "inline-block"
});

const RedTextTableCell = styled(BorderlessTableCell)(({ theme }) => ({
	color: theme.palette.primary.main
}));

const RoundedCell = styled("div")(({ theme }) => ({
	position: "relative",
	border: `2px solid ${theme.palette.grey[500]}`,
	borderRadius: "100%",
	width: "16px",
	marginTop: "18px",
	backgroundColor: theme.palette.grey[500],
	zIndex: 100,
	height: "16px"
}));

const ConnectedRoundedCell = styled(RoundedCell)(({ theme }) => ({
	"&::before": {
		position: "absolute",
		border: `1px solid ${theme.palette.grey[500]}`,
		width: 0,
		height: "36px",
		display: "block",
		content: '""',
		left: "50%",
		zIndex: 3,
		top: "-38px",
		marginLeft: "-1px"
	}
}));

const TournamentDisplay = (props: Props) => {
	const theme = useTheme();

	if (!props.tournament?.rounds)
		return (<></>);

	return (
		<MinimalWidthTable>
			<colgroup>
				<col width="auto" />
				<col width="auto" />
				<col width="auto" />
			</colgroup>
			<TableBody>
				<TableRow>
					<BorderlessTableCell colSpan={3}>
						{props.tournament.name}
					</BorderlessTableCell>
				</TableRow>
				{props.tournament.rounds && props.tournament.rounds.map((r, i) => (
					<TableRow key={i}>
						<BorderlessTableCell
							sx={{ fontWeight: "bold" }}
						>
							{moment(r.startDate).format(dateFormats.time)}
						</BorderlessTableCell>
						{i === 0 && (
							<RoundedCell
								sx={{
									...(r.isRunning && !r.isFinished && {
										backgroundColor: theme.palette.info.main,
										border: `2px solid ${theme.palette.info.main}`
									})
								}}
							/>
						)}
						{i !== 0 && (
							<ConnectedRoundedCell
								sx={{
									...(r.isRunning && !r.isFinished && {
										backgroundColor: theme.palette.info.main,
										border: `2px solid ${theme.palette.info.main}`
									})
								}}
							/>
						)}
						<RedTextTableCell>{r.roundName}</RedTextTableCell>
					</TableRow>
				))}
			</TableBody>
		</MinimalWidthTable>
	);
}

export default TournamentDisplay;
