import React from 'react';
import { CircularProgress, CssBaseline, ThemeProvider } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import moment from "moment";
import "moment/locale/de";
import TournamentOverview from "./components/TournamentOverview";
import useAsyncEffect from "./useAsyncEffect";
import axios from "axios";
import { RecoilRoot, useRecoilState } from "recoil";
import { uiConfiguration } from "./system/atoms";
import badBevensenTheme from "./themes/bad-bevensen-theme";
import glindeTheme from "./themes/glinde-theme";
import michelPokalTheme from "./themes/michel-pokal-theme";
import saltatioTheme from "./themes/saltatio-theme";
// import "moment-timezone";

moment.locale("de");

export interface UiConfiguration {
	logo: string 
	theme: "bad-bevensen" | "glinde" | "saltatio" | "michel-pokal"
}

const Config = () => {
	const [config, setConfig] = useRecoilState(uiConfiguration);

	useAsyncEffect(async () => {
		//const response = await axios.get<UiConfiguration>(`https://localhost:7051/uiconfiguration`);
		const response = await axios.get<UiConfiguration>(`/uiconfiguration`);

		if (response.data) {
			setConfig(response.data);
		}
	}, []);
	
	const theme = config?.theme === "bad-bevensen" ? badBevensenTheme :
			config?.theme === "glinde" ? glindeTheme :
				config?.theme === "michel-pokal" ? michelPokalTheme :
					config?.theme === "saltatio" ? saltatioTheme 
						: saltatioTheme;
	
	return (
		<>
			{!config && (
				<CircularProgress size={48} />
			)}
			{config && (
				<ThemeProvider theme={theme}>
					<CssBaseline>
						<Routes>
							<Route path="*" element={<TournamentOverview />} />
							<Route path="/overview" element={<TournamentOverview />} />
							<Route path="/overview/:id" element={<TournamentOverview />} />
						</Routes>
					</CssBaseline>
				</ThemeProvider>
			)}
		</>
	);
};

export default function App() {
	return (
		<RecoilRoot>
			<Config />
		</RecoilRoot>
	);
}

App.displayName = App.name
