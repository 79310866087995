import { Box, styled, TableCell, TableRow } from "@mui/material";

export const BorderlessTableCell = styled(TableCell)({
	border: "none",
});

export const NotificationBox = styled(Box)(({ theme }) => ({
	textTransform: "uppercase",
	textAlign: "center",
	color: theme.palette.primary.main,
	fontSize: "20px"
}));

export const TurnedTableCell = styled(Box)({
	writingMode: "vertical-lr",
	webkitTransform: "rotate(180deg)",
	mozTransform: "rotate(180deg)",
	oTransform: "rotate(180deg)",
	msTransform: "rotate(180deg)",
	transform: "rotate(180deg)",
	whiteSpace: "nowrap",
	display: "block",
	bottom: 0,
	width: "20px",
	// height: "20px",
	fontSize: "30px",
	translate: "20px",
	maxWidth: "20px"
});

export const BorderedTableCell = styled(TableCell)({
	borderBottom: "none",
	borderLeft: "1px solid #e0e0e0"
});

export const FirstTableCell = styled(BorderedTableCell)({
	fontWeight: "bold",
	textAlign: "right",
	borderLeft: "none"
});

export const HeaderTableCell = styled(TableCell)(({ theme }) => ({
	backgroundColor: theme.palette.primary.main,
	color: "white",
	padding: 1
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: "white",
	},
	'&:nth-of-type(even)': {
		backgroundColor: "#F6F6F6",
	},
}));
